import React from 'react';
import {authSteps} from "./authSteps";

function ProviderType(props) {

    function onClickSms(e) {
        e.preventDefault()
        props.setType('SMS')
    }
    function onClickTelegram(e) {
        e.preventDefault()
        props.setType('TELEGRAM')
    }
    if (props.step === authSteps.STATE_SUCCESS ) {
        return null;
    }

    return (
        <div className="max-w-xs mb-2">
            <p className="text-left font-medium mb-4 text-textLight dark:text-textDarkLightGray text-lg">
                Выберите как хотите получить код:</p>
            <div className="soa-property-container inline-flex rounded-md shadow-xs max-w-xs w-full mb-3 justify-center"
                 role="group">
                <button type="button" onClick={onClickSms}
                        className={"px-5 py-2 text-lg font-medium w-full dark:text-white border border-gray-300 dark:border-gray-600 transition duration-500 "
                            + (props.type === 'SMS'
                                ? "bg-light-red  text-white dark:bg-light-red "
                                : "text-gray-900 bg-white hover:bg-black/10 dark:bg-darkBox hover:dark:bg-white/10")
                            + (props.tgEnabled === 'Y' ? ' rounded-s-lg' : ' rounded-lg')}>
                    SMS
                </button>
                <button type="button" onClick={onClickTelegram}
                        className={"px-5 py-2 text-lg font-medium w-full dark:text-white border border-gray-300 dark:border-gray-600 transition duration-500 rounded-e-lg "
                            + (props.type === 'TELEGRAM'
                                ? "bg-light-red  text-white dark:bg-light-red "
                                : "text-gray-900 bg-white hover:bg-black/10 dark:bg-darkBox hover:dark:bg-white/10")
                            + (props.tgEnabled === 'Y' ? '' : ' hidden')}>
                    Telegram
                </button>
            </div>
        </div>

    );
}

export default ProviderType;